import React, { useState } from 'react';
import styled from 'styled-components';

import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import { lightBackgroundBlue, primary } from 'library/colors';
import {
  localDate_formatISO,
  MONTH,
  YEARS,
} from 'library/services/dateServices';

const AgentsReportId_19 = () => {
  const [campaign, setCampaign] = useState('varlix_events_01');

  const [startDate, setStartDate] = useState(localDate_formatISO().substring(0, 10))
  const [endDate, setEndDate] = useState(localDate_formatISO().substring(0, 10))

  return (
    <Cont>
      <Title>Reporte de agentes</Title>
      <BlueCont>
        <SCont>
          <SelectCont maxWidth="120px">
            <Label margin="0">Inicio:</Label>
            <Input type="date" value={startDate} onChange={e=>setStartDate(e.target.value)}/>
          </SelectCont>

          <SelectCont maxWidth="120px">
            <Label margin="0">Fin:</Label>
            <Input type="date" value={endDate} onChange={e=>setEndDate(e.target.value)}/>
          </SelectCont>
          
        </SCont>
        <DownloadReportButton
          reportType={19}
          params={`start=${startDate}&end=${endDate}&force=true`}
        >
          Reporte
        </DownloadReportButton>
      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 0px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: end;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const SCont = styled.div`
  flex-grow: 1;
  display: flex;
`;
const SelectCont = styled.div<ISelectCont>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '200px')};
`;
const Label = styled.label<ILabel>`
  font-size: 0.7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const Input = styled.input`
  flex-grow: 1;
  padding-left: 5px;
  /* border-radius: 10px; */
  background-color: white;
  /* border: 2px solid ${primary}; */
  border: none;
  outline: none;
`;
interface ISelectCont {
  maxWidth?: string;
}
interface ILabel {
  margin?: string;
}
export default AgentsReportId_19;
