import React, { FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import TotalCallByOriginTemplate from './reports/totalCallsByOriginTemplate';
import CallDetailsExcelTemplate from './reports/callDetailsExcel';
import ByBotExtensionTemplate from './reports/byBotExtension';
import CallSummarize from './reports/callsSummarize';
import WhatsAppCampaign from './reports/whatsappCampoign';
import QueueReport from './reports/queueReport';
import CallsReportType8 from './reports/callsReportType8';
import AgentsReportType9 from './reports/agentsReportType9';
import EventsByBotExtensionDetailed from './reports/eventsByBotExtensionDetailed';
import EmailReport from './reports/emailsReport';
import CustomEventsReportType11 from './reports/customEventsReportType11';
import WhatsappUsage from './reports/whatsappUsage';
import TransferReportId_13_14 from './reports/transfersReportType13';
import SurveyReport from './reports/surveyReportType15';
import AgentsLogsReportType16 from './reports/agentLogsReportType16';
import TiempoDeConexionDeAgentesReportType17 from './reports/ReportType17';
import EventsReportId_18 from './reports/reportType_18_events';
import AgentsReportId_19 from './reports/reportType_19_agents';

interface IReportTemplateProps { }
const ReportTemplate: FC<IReportTemplateProps> = ({ }) => {

  const { reportTypeId } = useParams() as any

  return (
    <Cont>
      {(reportTypeId === undefined || reportTypeId === 'totalCallsByOrigin') && <TotalCallByOriginTemplate />}
      {reportTypeId === 'eventsByBotDetailed' && <EventsByBotExtensionDetailed />}
      {reportTypeId === 'emailsReport' && <EmailReport />}
      {reportTypeId === 'whatsappUsage' && <WhatsappUsage />}
      {reportTypeId === '1' && <ByBotExtensionTemplate reportTypeId={reportTypeId} />}
      {reportTypeId === '2' && <CallDetailsExcelTemplate reportTypeId={reportTypeId} />}
      {reportTypeId === '3' && <CallSummarize reportTypeId={reportTypeId} />}
      {reportTypeId === '7' && <WhatsAppCampaign reportTypeId={reportTypeId} />}
      {reportTypeId === 'queuesReport' && <QueueReport />}
      {reportTypeId === '8' && <CallsReportType8 reportTypeId={reportTypeId} />}
      {reportTypeId === '9' && <AgentsReportType9 reportTypeId={reportTypeId} />}
      {reportTypeId === '11' && <CustomEventsReportType11 reportTypeId={reportTypeId} />}
      {reportTypeId === '13' && <TransferReportId_13_14 reportTypeId={reportTypeId} />}
      {reportTypeId === '14' && <TransferReportId_13_14 reportTypeId={reportTypeId} />}
      {reportTypeId === '15' && <SurveyReport />}
      {reportTypeId === '16' && <AgentsLogsReportType16 reportTypeId={reportTypeId} />}
      {reportTypeId === '17' && <TiempoDeConexionDeAgentesReportType17 reportTypeId={reportTypeId} />}
      {reportTypeId === '18' && <EventsReportId_18 />}
      {reportTypeId === '19' && <AgentsReportId_19 />}
      {reportTypeId === '20' && <AgentsLogsReportType16 reportTypeId={reportTypeId} title='Sesiones de agentes'/>}
    </Cont>
  );
};
const Cont = styled.div`
flex-grow: 1;
`;
export default ReportTemplate;