import React, { useState } from 'react';
import styled from 'styled-components';

import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import { lightBackgroundBlue, primary } from 'library/colors';
import {
  localDate_formatISO,
  MONTH,
  YEARS,
} from 'library/services/dateServices';

const EventsReportId_18 = () => {
  const [campaign, setCampaign] = useState('varlix_events_01');

  const [date, setDate] = useState({
    year: localDate_formatISO().substring(0, 4),
    month: localDate_formatISO().substring(5, 7),
  });
  return (
    <Cont>
      <Title>Reporte de eventos</Title>
      <BlueCont>
        <SCont>
          <SelectCont>
            <Label margin="0">Selecciona la Fuente: </Label>
            <Select onChange={e => setCampaign(e.target.value)}>
              <option value={campaign}>varlix_events_01</option>
            </Select>
          </SelectCont>
          <SelectCont maxWidth="120px">
            <Label margin="0">Mes del reporte: </Label>
            <Select
              value={date.month}
              onChange={e =>
                setDate(prev => ({ ...prev, month: e.target.value }))
              }
            >
              {MONTH.map((month, index) => {
                return (
                  <option key={index} value={month.value}>
                    {month.label}
                  </option>
                );
              })}
            </Select>
          </SelectCont>
          <SelectCont maxWidth="70px">
            <Label margin="0">Año: </Label>
            <Select
              value={date.year}
              onChange={e =>
                setDate(prev => ({ ...prev, year: e.target.value }))
              }
            >
              {YEARS.map((year, index) => {
                return (
                  <option key={index} value={year}>
                    {year}
                  </option>
                );
              })}
            </Select>
          </SelectCont>
        </SCont>
        <DownloadReportButton
          reportType={18}
          params={`campaign=${campaign}&start=${date.year}-${date.month}&force=true`}
        >
          Reporte
        </DownloadReportButton>
      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 0px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: end;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const SCont = styled.div`
  flex-grow: 1;
  display: flex;
`;
const SelectCont = styled.div<ISelectCont>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '200px')};
`;
const Label = styled.label<ILabel>`
  font-size: 0.7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const Select = styled.select`
  flex-grow: 1;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
interface ISelectCont {
  maxWidth?: string;
}
interface ILabel {
  margin?: string;
}
export default EventsReportId_18;
