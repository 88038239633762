/**
 * return the current date with format yyyy-mm-dd
 */
export const getToday_yyyymmdd = (): string => {
  const date = localDate_formatISO();
  // ISO string format 2022-11-02T00:00:00.000Z
  return date.split('T')[0];
};

/**
 * return the first day of current month with format yyyy-mm-dd
 */
export const getFirstDayOfCurrentMonth_yyyymmdd = (): string => {
  const dateObj = new Date();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  // Date format mm/dd/yyy
  const date = new Date(`${month}/01/${year}`);

  return date.toISOString().split('T')[0];
};

/**
 * returns true if the date is after today.
 *
 * returns false if the date is today or a day before.
 *
 * @param date - should be a valid date format
 */
export const isDateAfterToday = (date: string): boolean => {
  const DateObj = new Date();
  const today = new Date(
    `${DateObj.getFullYear()}-${DateObj.getMonth() + 1}-${DateObj.getDate()}`,
  );
  const paramDate = new Date(date);
  return paramDate > today;
};

/**
 * returns true if the first date after to second date
 *
 * returns false if the first date is before or equal to second date
 *
 * @param firstDate - should be a valid date format
 * @param secondDate - should be a valid date format
 */
export const isFirstDateAfterToSecondDate = (
  firstDate: string,
  secondDate: string,
): boolean => {
  const initDate = new Date(firstDate);
  const finalDate = new Date(secondDate);
  return initDate > finalDate;
};

/**
 * returns true if the number of days between date_start and date_end
 * is less than or equal to the specified range of days
 *
 * @param rangeOfDays - maximun range days
 * @param date_start - should be a valid date format
 * @param date_end - should be a valid date format
 */
export const areDatesBetweenRangeOfDays = (
  rangeOfDays: number,
  date_start: string,
  date_end: string,
): boolean => {
  const MILISECONDS_IN_A_DAY = 60 * 60 * 24 * 1000;
  const milisecondsInTheRange = rangeOfDays * MILISECONDS_IN_A_DAY;

  const iniDate = new Date(date_start).valueOf();
  const finalDate = new Date(date_end).valueOf();

  const distanceBetweenDays = finalDate - iniDate;

  return milisecondsInTheRange > distanceBetweenDays;
};

/**
 * returns the offset in format +-hhmm
 *
 * ex: -0400
 * ex: 0400
 *
 * @param minOffset - the result of new Date().getTimezoneOffset()
 */
export const getTimezoneOffset = (minOffset?: number): string => {
  const offSet: number = minOffset ? minOffset : new Date().getTimezoneOffset();

  const min = offSet > 0 ? offSet : offSet * -1;

  const date = new Date(min * 60 * 1000);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  const sign = offSet > 0 ? `-` : `+`;
  return `${sign}${hours}${minutes}`;
};
/**
 * convert local date to UTC with format ISO
 *
 * receive format dates from input and ISO:
 * 'yyyy-mm-dd' | 'yyyy-mm-ddThh:mm:ss:mmmZ'
 *
 * example: '2022-01-01' | '2022-01-01T00:00:00.000Z'
 *
 * timezoneOffset can be undefined, string or number in hours or minutest like js return the offset
 *
 * if timezoneOffset is undefined, take the timezoneOffset from system
 *
 * @param { string } date_FormatISO - string - can be 'yyyy-mm-dd' | ISO format
 *
 * @param { string | number | undefined } timezoneOffset - string | number | undefined - can be undefined | '-3' | '3' | -5 | 5 | 240 | -240
 */
export const localDateToUTC_formatISO = (
  date_FormatISO: string,
  timezoneOffset?: string | number,
): string => {
  let timeOffset_MIN: string | number = new Date().getTimezoneOffset();

  if (typeof timezoneOffset === 'string') {
    timeOffset_MIN = parseFloat(timezoneOffset) * -60;
  } else if (typeof timezoneOffset === 'number') {
    timezoneOffset >= -12 && timezoneOffset <= 12
      ? (timeOffset_MIN = timezoneOffset * -60) // when timezoneOffset is number of hours
      : (timeOffset_MIN = timezoneOffset); // when timezoneOffset is number of minutes
  }

  const res = new Date(date_FormatISO);
  const rst = new Date(timeOffset_MIN * 60 * 1000);
  const send = new Date(res.getTime() + rst.getTime()).toISOString();

  return send;
};
/**
 * Converts a date in UTC ISO format to a date in ISO format in the local time zone.
 *
 * @param {string} dateUTCISO - The date in UTC ISO format to be converted.
 * @returns {string} The converted date in ISO format in the local time zone.
 */
export const dateUTCISO_localDateFormatISO = (dateUTCISO: string): string => {
  const date = new Date(dateUTCISO);
  const offSet = date.getTimezoneOffset();

  const min = offSet > 0 ? offSet : offSet * -1;
  return new Date(date.getTime() - min * 60000).toISOString();
};
/**
 * @returns the local date and time in format ISO
 */
export const localDate_formatISO = (): string => {
  const timeOffset_MS = new Date().getTimezoneOffset() * 60 * 1000;
  const date = new Date(Date.now() - timeOffset_MS).toISOString();
  return date.slice(0, -4) + '999Z';
};
/**
 * this function calculate the exact day and time
 * from specified numbers of days before now in format ISO
 *
 * @param numberOfDays - number of days before to calculate
 * @returns the local date and time from specified numbers of days before now
 */
export const nDaysBeforeNow_LocalTime_formatISO = (
  numberOfDays: number,
): string => {
  const date = new Date(localDate_formatISO());
  date.setDate(date.getDate() - numberOfDays);
  return date.toISOString().slice(0, -4) + '999Z';
};
/**
 *
 * @param inputDateFormat
 * @returns
 */
export const inputDateFormat_toRenderFormat = (
  inputDateFormat: string,
): string => {
  return inputDateFormat.split('-').reverse().join('-');
};
/**
 * return true if date is before today
 *
 * return false if date is today or after
 *
 * @param date - should be a valid date format
 */
export const isDateBeforeToday = (date: string): boolean => {
  const today = new Date(getToday_yyyymmdd());
  return today > new Date(date);
};
/**
 * return true if the evaluated day is between the start and end dates,
 * excluding those two dates
 *
 * return false if the evaluated day is not between the start and end dates,
 * or is equal to start or end date
 *
 * @param startDate - should be a valid date format
 * @param endDate - should be a valid date format
 * @param dateToEvaluate - should be a valid date format
 */
export const isDateBetweenTheTwoDates = (
  startDate: string,
  endDate: string,
  dateToEvaluate: string,
) => {
  const initDate = new Date(startDate + 'T23:59:59.999Z');
  const finalDate = new Date(endDate + 'T00:00:00.000Z');
  const evaluatedDate = new Date(dateToEvaluate);
  return initDate < evaluatedDate && finalDate > evaluatedDate;
};

export const getSecondsDifference = (initialDate: string) => {
  const currentDate = new Date();
  const parsedInitialDate = new Date(initialDate);

  const differenceInMilliseconds =
    currentDate.getTime() - parsedInitialDate.getTime();
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

  return differenceInSeconds;
};

/**
 * toma un string de fecha en formato iso y elimina la Z, el . y los milisegundos
 */
export const dateIsoFormated = (timestamp: string): string => {
  const isoRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

  const localDateIso = isoRegExp.test(timestamp)
    ? dateUTCISO_localDateFormatISO(timestamp)
    : timestamp;
  const withoutZ = localDateIso.replace('T', ' ');
  const withoutMiliseconds = withoutZ.slice(0, -5);
  return withoutMiliseconds;
};

export const MONTH = [
  { label: 'Enero', value: '01', DateMonth: 0 },
  { label: 'Febrero', value: '02', DateMonth: 1 },
  { label: 'Marzo', value: '03', DateMonth: 2 },
  { label: 'Abril', value: '04', DateMonth: 3 },
  { label: 'Mayo', value: '05', DateMonth: 4 },
  { label: 'Junio', value: '06', DateMonth: 5 },
  { label: 'Julio', value: '07', DateMonth: 6 },
  { label: 'Agosto', value: '08', DateMonth: 7 },
  { label: 'Septiembre', value: '09', DateMonth: 8 },
  { label: 'Octubre', value: '10', DateMonth: 9 },
  { label: 'Noviembre', value: '11', DateMonth: 10 },
  { label: 'Diciembre', value: '12', DateMonth: 11 },
];

export const YEARS = [2025, 2024, 2023, 2022];

export const sortByDate = <T>(
  a: T & { updated_at: string },
  b: T & { updated_at: string },
) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
