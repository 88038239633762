export type IConversationChannelType = 'whatsapp' | 'facebook' | 'instagram';

export interface IConversationEnabledChannel {
  channelType: IConversationChannelType;
  value: string;
}

export const whatsappIsEnabled: { [key: string]: IConversationEnabledChannel } =
  {
    CASMU: { channelType: 'whatsapp', value: '59894392649' },
    POC: { channelType: 'whatsapp', value: '59892355751' },
    VARLIX: { channelType: 'whatsapp', value: '59897974433' },
    IMPAYSANDU: { channelType: 'whatsapp', value: '59847227422' },
    NDM: { channelType: 'whatsapp', value: '59892925537' },
  };

export const conversationInWorkspaceIsEnabled: {
  [key: string]: IConversationEnabledChannel[];
} = {
  CASMU: [{ channelType: 'whatsapp', value: '59894392649' }],
  POC: [
    { channelType: 'whatsapp', value: '59892355751' },
    { channelType: 'facebook', value: 'facebook' },
    { channelType: 'instagram', value: 'instagram' },
  ],
  CONSULADOUY: [
    // { channelType: 'whatsapp', value: '59892355751' },
    { channelType: 'facebook', value: 'facebook' },
    { channelType: 'instagram', value: 'instagram' },
  ],
  VARLIX: [{ channelType: 'whatsapp', value: '59897974433' }],
  IMPAYSANDU: [{ channelType: 'whatsapp', value: '59847227422' }],
  NDM: [{ channelType: 'whatsapp', value: '59892925537' }],
};
